import React, { Component } from "react";
import recife from "../assets/images/recife.jpeg";
import bh from "../assets/images/bh.jpeg";
class Home extends Component {
    render() {
        return (
            <div className="d-flex page-container">
                <div className="d-flex flex-column">
                    <h1 className="page-title p-3 fs-4">Nossos eventos</h1>
                    <div className="container">
                        <div className="d-flex flex-column aling-items-center">
                            <img src={recife} alt="banner evento" />
                            <h4 className="pt-4 pb-5">
                                Garanta sua{" "}
                                <a
                                    href="https://forms.gle/Wc2S5MPh24yuscQ47"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    inscrição aqui
                                </a>
                                !
                            </h4>
                            <img src={bh} alt="banner evento" />
                            <h4 className="pt-4 pb-5">
                                Garanta sua{" "}
                                <a
                                    href="https://forms.gle/eADfUtJ6VPDTDBZ38"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    inscrição aqui
                                </a>
                                !
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
