import React, { Component } from "react";
import joao_campos from "../assets/images/joao_campos.jpg";
import sipat from "../assets/images/sipat.jpeg";
import sesmt from "../assets/images/sesmt.png";

export class NextEvents extends Component {
    render() {
        return (
            <div className="d-flex page-container">
                <div className="d-flex flex-column">
                    <h1 className="page-title p-3 fs-4">Nossos eventos</h1>
                    <div className="container">
                        <div className="d-flex flex-column aling-items-center">
                            <img src={joao_campos} alt="banner evento" />
                            <h4 className="pt-4 pb-5">
                                Garanta sua{" "}
                                <a
                                    href="https://www.linkedin.com/posts/jo%C3%A3o-carlos-pires-campos_eu-apoio-a-canpat-estarei-acompanhando-o-activity-7186348847474888704-Om_a/?utm_source=share&utm_medium=member_desktop"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    inscrição aqui
                                </a>
                                !
                            </h4>
                        </div>
                        <div>
                            <img src={sipat} alt="banner evento" />
                            <h4 className="pt-4 pb-5">
                                Garanta sua{" "}
                                <a
                                    href="https://www.megasipat.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    inscrição aqui
                                </a>{" "}
                                ou no e-mail: agaldino@sindusconsp.com.br !
                            </h4>
                        </div>
                        <div>
                            <img src={sesmt} alt="banner evento" />
                            <h4 className="pt-4 pb-5">
                                Garanta sua{" "}
                                <a
                                    href="https://forms.gle/QmWTDN4yQfFRZ1Fg9"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    inscrição aqui
                                </a>
                                !
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NextEvents;
